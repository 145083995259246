import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import MaterialTable from '@web/materialui-table';
import { withTranslation } from 'react-i18next';


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  switch: {
    float: 'right'
  }
});

const columns = [
  { id: 'num', numeric: true, disablePadding: false, sortable: true, label: '#' },
  { id: 'name', numeric: false, disablePadding: false, sortable: true, label: 'Nom' },
  { id: 'current_exercise', numeric: false, disablePadding: true, sortable: true, label: 'Exercice en cours' },
  { id: 'role_name', numeric: false, disablePadding: false, sortable: true, label: 'Rôle' },
  { id: 'country_name', numeric: false, disablePadding: false, sortable: true, label: 'Pays' },
  { id: 'legal_form', numeric: false, disablePadding: false, sortable: true, label: 'Forme légale' },
  { id: 'folder_type', numeric: false, disablePadding: false, sortable: true, label: 'Type' },
  { id: 'public', numeric: false, disablePadding: false, sortable: true, label: 'Public' },
  { id: 'subscription_status', numeric: false, disablePadding: false, sortable: true, label: 'Abonnement'}
];

export class FolderList extends Component
{
  constructor(props) {
    super(props);

    this.handleClickHistory = this.handleClickHistory.bind(this);
    this.handleClickImport = this.handleClickImport.bind(this);
  }
  
  componentDidMount() {
    document.title = 'Liste dossiers';

    const { match, searchFolders, getUser } = this.props;
    getUser(match.params.userId).then(() => {
      searchFolders(match.params.userId);
    });
  };

  handleClickHistory(data) {
    const { history, match } = this.props;
    history.push(`/history/${match.params.userId}/${data.id}`);
  }

  handleClickImport(data) {
    const { history, match } = this.props;
    history.push(`/import-history/${match.params.userId}/${data.id}`);
  }

  render() {
    const { classes, folders, toggleFilter, publicFolder, t } = this.props;

    return (
      <>
        <Typography variant="h4" gutterBottom component="h2">
          Liste des dossiers
        </Typography>
        <Paper className={classes.root}>
          <FormControlLabel
            className={classes.switch}
            control={
              <Switch
                checked={publicFolder}
                onChange={event => toggleFilter(event.target.checked)}
                value="publicFolder"
                color="primary"
              />
            }
            label="Dossiers publics"
          />
          <MaterialTable
            className={classes.userTable}
            columns={columns}
            data={folders.map(folder => ({
              ...folder,
              role_name: t(folder.role_name)
            }))}
            emptyMessage='Aucun dossier trouvé'
          >
            <Button variant="contained" color="primary" onClick={this.handleClickHistory} className={classes.button}>
              Historique
            </Button>
            <Button variant="contained" color="secondary" onClick={this.handleClickImport} className={classes.button}>
              Écritures
            </Button>
          </MaterialTable>
        </Paper>
      </>
    )
  };
}

FolderList.propTypes = {
  classes: PropTypes.object.isRequired,
  folders: PropTypes.array,
  t: PropTypes.func.isRequired
};

FolderList.defaultProps = {
  folders: [],
  classes: {}
}

export default withStyles(styles)(withTranslation('common')(FolderList));
