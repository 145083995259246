import React, { Component } from 'react';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Search } from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';
import MaterialTable from '@web/materialui-table';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit
  },
  search: {
    marginTop: theme.spacing.unit * 2
  }
});

const columns = [
  { id: 'lastname', numeric: false, disablePadding: false, sortable: true, label: 'Nom' },
  { id: 'firstname', numeric: false, disablePadding: false, sortable: true, label: 'Prénom' },
  { id: 'firm_name', numeric: false, disablePadding: false, sortable: true, label: 'Cabinet' },
  { id: 'email', numeric: false, disablePadding: false, sortable: true, label: 'Email' }
];

export class SearchUsers extends Component
{
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'firstname',
      page: 0,
      rowsPerPage: 5,
    };

    this.handleClickRow = this.handleClickRow.bind(this);
    
  };

  handleClickRow(data) {
    const { history, getUser } = this.props;
    getUser(data.id);
    history.push(`/informations/${data.id}`);
  };

  render() {
    const { classes, search, users, handleChange } = this.props;
    document.title = 'Dashboard';

    return (
      <>
        <Typography variant="h4" gutterBottom component="h2">
          Accueil
        </Typography>
        <Input
          label="Name"
          className={classes.search}
          value={search}
          onChange={(event) => handleChange(event.target.value)}
          placeholder="Email du client"
          startAdornment={
            <InputAdornment position="start">
              <Search color="disabled" />
            </InputAdornment>
          }
          fullWidth
          autoFocus
        />
        <Paper className={classes.paper}>
          <MaterialTable
            columns={columns}
            data={users}
            clickRowEvent={this.handleClickRow}
            emptyMessage='Aucun client trouvé'
          />
        </Paper>
      </>
    )
  };
}

SearchUsers.propTypes = {
  users: PropTypes.array.isRequired,
  classes: PropTypes.object
};

SearchUsers.defaultProps = {
  users: [],
  classes: {}
};

export default withStyles(styles)(SearchUsers);
