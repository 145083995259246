import React, { Component } from 'react';
import MaterialTable from '@web/materialui-table';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

const styles = theme => ({
    root: {
      marginTop: theme.spacing.unit * 8,
    }
});

const columns = [
    { id: 'status', disablePadding: false, label: 'Status', sortable: true },
    { id: 'errors', disablePadding: false, label: 'Errors', sortable: true },
    { id: 'file_name', disablePadding: false, label: 'File name', sortable: true },
    { id: 'created_at', disablePadding: false, label: 'Date', sortable: true },
    { id: 'exercise_date', disablePadding: false, label: 'Exercise date', sortable: true },
    { id: 'balance_label', disablePadding: false, label: 'Balance', sortable: true },
    { id: 'account_length', disablePadding: false, label: 'Account number length', sortable: true },
    { id: 'account_validate', disablePadding: false, label: 'Same length', sortable: true }
];

export class ImportHistory extends Component
{
    componentDidMount() {
        document.title = 'Liste des imports';
        const { match, getImportHistory } = this.props;
        getImportHistory(match.params.folderId)
    }

    render() {
        const { classes, imports } = this.props;

        return (
            <div className={classes.root}>
                <Typography variant="h4" gutterBottom component="h2">
                    Historique des écritures
                </Typography>
                <MaterialTable 
                    columns={columns}

                    data={imports.map(item => ({
                        ...item,
                        balance_label:item.balance ? "équilibrée" : "non équilibrée",
                        account_validate:item.good_lengths ? "Ok" : "Longueurs numéros de comptes différentes !"
                      }))}

                    conditionalColors={
                        [
                            {condition: ['balance', 'eq', false], color:{background: '#F9FF00', font: '#000000'}},
                            {condition: ['good_lengths', 'eq', false], color:{background: '#FF0000', font: '#FFFFFF'}}
                        ]
                    }

                    handleClickAction={this.handleClickAction}
                    emptyMessage='Aucune importation trouvée'
                />
            </div>
        );
    }
}

ImportHistory.propTypes = {
    classes: PropTypes.object.isRequired,
    getImportHistory: PropTypes.func.isRequired,
    imports: PropTypes.array.isRequired
}

ImportHistory.defaultProps = {
    classes: {},
    imports: []
}

export default withStyles(styles)(ImportHistory);
